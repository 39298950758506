// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "trix"
import "@rails/actiontext"
import * as modules from './modules/index';

import Carousel from "@morsedigital/vanilla-carousel";

// No need to add guard check unless you dynamically load module
(() => {
  Carousel();
})();

