import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["body","title-cont","arrow"];
  
  
  toggle(event) {
    event.preventDefault();
    this.bodyTarget.classList.toggle("expanded");
    this.arrowTarget.classList.toggle("rotate180degs");    
  }
}